<!--
 * @Author: ttheitao
 * @Date: 2021-10-19 10:24:22
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-16 14:04:17
 * @Description: Excel模板库
-->
<template>
  <div class="excel-list-container">
    <div class="top-options">
      <div>
        <el-input v-model="keyword" @input="handleSearch" placeholder="输入搜索内容"></el-input>
      </div>
      <div>
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>
    </div>
    <div class="table-box scroller">
      <el-table v-loading="tableLoading" :data="tableData" border height="100%">
        <el-table-column prop="id" label="模板ID" width="100"> </el-table-column>
        <el-table-column prop="uuid" label="模板UUID"> </el-table-column>
        <el-table-column prop="name" label="模板名称"> </el-table-column>
        <el-table-column label="模板类型">
          <template slot-scope="scope">
            {{ getTypeName(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column label="所属UUID">
          <template slot-scope="scope">
            <div>{{ scope.row.owner_uuid }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            {{ scope.row.config ? scope.row.config.remark : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建日期"> </el-table-column>
        <el-table-column align="right" width="180" label="操作">
          <template slot-scope="scope">
            <span class="operation-primary" @click="handleEdit(scope.$index, scope.row)">编辑</span>
            <span
              class="operation-primary"
              @click="syncData(scope.$index, scope.row)"
              slot="reference"
              style="margin-left: 5px"
              >同步</span
            >
            <el-popconfirm
              title="确定复制吗？"
              @confirm="copy(scope.$index, scope.row)"
              style="margin-left: 5px"
            >
              <span class="operation-primary" slot="reference">复制</span>
            </el-popconfirm>
            <el-popconfirm
              title="确定删除吗？"
              @confirm="handleDel(scope.$index, scope.row)"
              style="margin-left: 5px"
            >
              <span class="operation-del" slot="reference">删除</span>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-box">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="currentPage"
        :page-size="size"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <Spreadsheet
      v-if="showModal"
      :modalTitle="modalTitle"
      :id="currentId"
      @close="close"
      @submit="submit"
    ></Spreadsheet>
  </div>
</template>

<script>
import spreadsheetApi from '@/api/spreadsheets';
import Spreadsheet from '@/components/spreadsheet/Spreadsheet.vue';
import obOpMixin from '@/views/objects/objectOperationMixin.js';
import { Popconfirm } from 'element-ui';

export default {
  name: 'list',
  components: {
    Spreadsheet,
    'el-popconfirm': Popconfirm,
  },
  mixins: [obOpMixin],
  data() {
    return {
      showModal: false,
      tableData: [],
      currentPage: 1,
      size: 14,
      total: 0,
      tableLoading: false,
      modalTitle: '新增模板',
      currentId: '',
      keyword: '',
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.tableLoading = true;
      spreadsheetApi.getListNoCache(this.currentPage, this.size, this.keyword).then((res) => {
        this.tableLoading = false;
        this.tableData = res.data.data.data;
        this.total = res.data.data.total;

        this.tableData.forEach((item) => {
          if (item.config && !Reflect.has(item.config, 'remark')) {
            item.config.remark = '';
          }
        });
      });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getData();
    },
    handleSizeChange(size) {
      this.size = size;
      this.getData();
    },
    handleAdd() {
      this.modalTitle = '新增模板';
      this.currentId = '';
      this.showModal = true;
    },
    handleEdit(index, row) {
      this.modalTitle = '编辑模板';
      this.currentId = row.id;
      this.showModal = true;
    },
    copy(index, row) {
      spreadsheetApi.copy(row.id).then(() => {
        this.$message.success('操作成功');
        this.getData();
      });
    },
    handleDel(index, row) {
      spreadsheetApi.del(row.id).then(() => {
        this.$message.success('操作成功');
        this.getData();
      });
    },
    handleSearch() {
      this.getData();
    },
    // 提交事件
    submit() {
      this.showModal = false;
      this.getData();
    },
    // spreadsheet组件模态框关闭（不包含提交）
    close() {
      this.showModal = false;
    },
    syncData(index, row) {
      this.syncSheetData(row.uuid, {
        success: () => {
          this.getData();
        },
      });
    },
    /**
     * @description: 返回类型名称
     * @param {Number} type 模板类型
     * @return {String}
     */
    getTypeName(type) {
      let types = ['数仓全局模板', '表单列表模板', '表单详情模板'];
      return types[type - 1];
    },
  },
};
</script>

<style scoped>
.excel-list-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  background-color: #FFFFFF;
}
.top-options {
  display: flex;
  justify-content: space-between;
}

.table-box {
  flex: 1;
  margin-top: 10px;
  overflow-y: auto;
}

.pagination-box {
  margin-top: 10px;
  display: flex;
  justify-content: right;
}
</style>

<style>
.spreadsheet-iframe-box .el-dialog__body {
  padding: 10px 20px;
}
</style>
