<!--
 * @Author: ttheitao
 * @Date: 2021-10-20 17:49:14
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-04 15:00:37
 * @Description: file content
-->
<template>
    <el-dialog custom-class="sss-dialog" :title="modalTitle" v-if="showModal" :visible.sync="showModal" v-loading="loading" fullscreen>
        <article class="dialog-main">
            <ss-iframe type="add" :excelUuid="id" loadType="preload" :show="true" elementId="elementId1" ref="sssEntry" @sss-uploaded="submit()"></ss-iframe>
        </article>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="close()">取 消</el-button>
            <el-button type="primary" size="small" @click="triggerSssUpload()">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import SsIframe from './SsIframe.vue';
export default {
    name: "spreadsheet",
    components: { SsIframe },
    props: {
        // 文件标识 支持 id 与 uuid
        id: { type: [String, Number], default: "" },
        // 模态框标题
        modalTitle: { type: String, default: "标题" },
    },
    data() {
        return {
            showModal: true,
            loading: false,
        }
    },
    watch: {
        showModal: function (val) {
            if (val == false) {
                this.close();
            }
        }
    },
    methods: {
        close() {
            // 模态框关闭事件
            this.loading = false;
            this.$emit('close');
        },
        submit() {
            this.loading = false;
            this.$emit('submit');
        },
        triggerSssUpload() {
            this.loading = true;
            this.$refs.sssEntry.upload();
        }
    }
}
</script>

<style scoped>
.dialog-main {
    height: 100%;
}
</style>
