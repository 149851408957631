<!--
 * @Author: ttheitao
 * @Date: 2022-01-20 15:54:47
 * @LastEditors: zx
 * @LastEditTime: 2022-12-09 17:58:24
 * @Description: excel组件
-->
<template>
    <div class="iframe-class" ref="iframeBox" v-loading="isLoading" element-loading-text="连接表格服务">
        <iframe class="create-iframe-class" ref="sssIframe" v-if="loadType === 'create'" :src="sssDomain" @load="iframeLoaded"></iframe>
    </div>
</template>

<script>
/* eslint-disable */
// 支持的操作类型
import { getToken } from '@/utils/tools';
let types = ['add', 'edit', 'info', 'export'];

export default {
    props: {
        // 渲染类型 preload - 预加载（通过css绝对定位实现，目前仅支持弹窗），create - 新实例（每次调用都会重新请求外部资源）, local - 引入本地资源(未处理)
        loadType: { type: String, default: 'preload' },
        solveType: { type: String, default: 'form'},
        // 操作类型：add - 新增，edit - 编辑，info - 详情，export - 导出
        type: {
            type: String,
            default: 'add',
            required: true,
            validator: function (value) {
                return types.indexOf(value) !== -1;
            }
        },
        // excel模板 uuid
        excelUuid: { type: [String, Number], default: '' },
        // 对象 uuid
        objectUuid: { type: String, default: '' },
        // 视图 uuid
        viewUuid: { type: String, default: '' },
        // 视图 search (导出列表时)
        viewSearch: {
            type: Array,
            default: function () {
                return [];
            }
        },
        // 视图 query（params）导出列表时
        viewQuery: {
            type: Object,
            default: function () {
                return {};
            }
        },
        // 表单 uuid（仅在新增时候使用）
        formUuid: { type: String, default: '' },
        // 数据 ids
        dataIds: { type: [Number, String, Array] },
        // 导出类型 [excel | image | pdf]
        exportType: { type: String, default: 'excel' },
        // 外部传入metadata
        excelMetaData: {
            type: [Object, null],
            required: false,
            default: null
        },
        // 外部传入表单配置
        excelFormData: {
            type: [Object, null],
            required: false,
            default: null
        },
        // 是否显示
        show: { type: Boolean, default: false },
        needFlow: { type: Boolean, default: false },
        displayData: {
            type: [Object, null],
            required: false,
            default: null
        },
        // 元素ID，用以postMessage回调时的标识认证
        elementId:{ type: String, default: 'global' }
    },
    data() {
        return {
            /* speradsheet 参数 */
            // 地址
            sssDomain: 'https://sss.bimcc.com',
            // 当前excel是否为模版，设置为true则会在渲染完成后清空id
            isTemplate: false,
            // 是否显示工具栏
            showToolbar: true,
            /**
             * 渲染模式， show - 预览，form - 表单， bind - 绑定
             * 渲染模式在 showToolbar 为 true 的情况下可以在内部切换
             * show模式不可以做任何编辑操作，且不显示表单控件
             * form模式只可以对绑定有表单控件或表单收集配置的单元格操作
             * bind模式仅在配置的时候使用
             */
            renderMode: 'bind',
            // 提交时是保存excel模板，为 false 时仅保存模版名称
            isSave: true,
            /**
             * formdata:{
             *      objectUuid1:{},
             *      objectUuid2:[],
             * }
             * 数据键必须是uuid，为每条数据（包括子表）生成一个新的属性'__id__'，存放数据的id，如果无则为空
             */
            formData: {},
            // 预设值
            presetValues: {},
            // 渲染完成后自动下载[excel | image | pdf]
            autoDownLoad: '',
            // 固化内容，渲染过程不再请求远程数据、不渲染传入的表单数据、预设值等
            fixed: false,
            /* 过程参数 */
            // iframe 加载状态
            isLoad: false,
            // 数据 加载状态
            dataDownloaded: false,
            dataMetadata: {},
            // 全局spreadsheet Iframe
            sssIframe: null,
            // 监听参数
            parentDom: null,
            observer: null,
            // loading
            isLoading: false,
            // 流程参数
            editFieldsValue: {},
            showFlow: false,
            flow_target_users: [],
            flow_send_users: [],
            flow_select_step: '',
            flow_picker_mum: 0,
            // sss 层级
            zIndex: 0,
        };
    },
    created() {
        this.isLoad = true;
        this.isLoading = true;
    },
    watch: {
        // show(newValue) {
        //     if (this.loadType == 'preload') {
        //         if (newValue && this.type != 'export') {
        //             this.showSssIframe();
        //         } else {
        //             this.hideSssIframe();
        //         }
        //     }
        // }
    },
    mounted() {
        window.addEventListener('message', this.iframePostMessage);

        if (this.loadType == 'preload') {
            window.onresize = () => { this.updateSize() }
            this.sssIframe = document.getElementById('sssIframe');
        } else {
            this.sssIframe = this.$refs.sssIframe;
        }

        this.resolveType();

        if (this.type != 'export' && this.loadType == 'preload') {
            this.parentDom = this.$parent.$el.getElementsByClassName('dialog-main')[0];
            this.zIndex = parseInt(this.$parent.$el.style.zIndex) + 1;
            this.updateSize();

            // 监听父组件的属性变化更新宽高
            let MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
            this.observer = new MutationObserver(() => {
                this.updateSize();
            });
            this.observer.observe(this.$parent.$el, { attributes: true, childList: false, characterData: false });
        }
    },
    methods: {
        // 解析操作类型
        resolveType() {
            switch (this.type) {
                case 'add':
                    this.dataDownloaded = true;
                    this.start();
                    break;
                case 'edit':
                    this.getDataInfo();
                    break;
                case 'info':
                    this.renderMode = 'show';
                    this.getDataInfo();
                    break;
                case 'export':
                    this.renderMode = 'show';
                    this.autoDownLoad = this.exportType;
                    this.getDataInfo();
                    break;
            }
        },
        // 获取表单数据详情
        getDataInfo() {

        },
        // 检查是否包含分页
        checkPaginate(data) {
            if (Reflect.has(data, 'current_page')) {
                return true;
            }
            return false;
        },
        // iframe 加载完成
        iframeLoaded() {
            this.isLoad = true;
            this.start();
        },
        // 开始加载
        start() {
            // 当所有异步加载完成才渲染表格
            if (this.isLoad && this.dataDownloaded) {
                this.handleIframe();
            }
        },
        // 处理传入的数据，附加 __id__ 标识
        solveFormData(data) {
            for (let key in data) {
                if (
                    Reflect.has(this.dataMetadata, key) &&
                    this.dataMetadata[key] == 'id'
                ) {
                    data.__id__ = data[key];
                } else if (
                    Object.prototype.toString.call(data[key]) === '[object Object]' ||
                    Array.isArray(data[key])
                ) {
                    this.solveFormData(data[key]);
                }
            }
        },
        // 发送数据到表格
        handleIframe() {
            let bimcctoken = "";
            // 这里获取的token是假数据，实际项目直接获取bimcctoken
            let mockUserInfo = localStorage.getItem("mockUserInfo");
            if (mockUserInfo) {
                mockUserInfo = JSON.parse(mockUserInfo);
                bimcctoken = mockUserInfo.token;
            }
            let projectInfo = JSON.parse(localStorage.getItem('projectInfo'));

            this.sssIframe.contentWindow.postMessage({
                event: 'spreadsheetInit',
                options: {
                    baseUrl: projectInfo.originURl ? projectInfo.originURl : 'https://saas-api.bimcc.net/',
                    metadataToken: projectInfo.metadatatoken ? projectInfo.metadatatoken : 'aba62ca1-c2ff-42af-9d3a-bbe5b02f7b06',
                    bimcctoken: bimcctoken || getToken() || '',
                    id: this.excelUuid,
                    isTemplate: this.isTemplate,
                    renderMode: this.renderMode,
                    solveType: 'form',
                    renderMode: 'bind',
                    showToolbar: this.showToolbar,
                    isSave: this.isSave,
                    formData: JSON.parse(JSON.stringify(this.formData)),
                    presetValues: JSON.parse(JSON.stringify(this.presetValues)),
                    autoDownLoad: this.autoDownLoad,
                    fixed: this.fixed,
                    extra: {
                        archiId: '',
                        archiType: '',
                        entryType: this.type,
                        elementId: this.elementId,
                    }
                }
            }, '*');
        },
        // 监听表格事件
        iframePostMessage(e) {
            console.log(e.data,this.isLoading, 'sss回调');
            // 内部提交
            // if (e.data.event == 'spreadsheetSubmit') { }

            // sss开始实例化事件
            if(e.data.elementId !== this.elementId){
                return;
            }
            
            if (e.data.event === 'spreadsheetRun') {
                if (this.type != 'export' && this.show) {
                    this.showSssIframe();
                    this.isLoading = false;
                }
            }

            // 打开流程审批
            if (e.data.event === 'spreadsheetOpenFlow') {
                this.showFlow = true;
            }

            // 导出完成
            if (e.data.event === 'spreadsheetAdSucceed') {
                this.$emit('exportSuccess');
            }

            // 导出错误
            if (e.data.event === 'spreadsheetError') {
                console.log(e.data.message, '-----')
                this.$emit('exportError', e.data.message);
            }

            // 表达数据响应事件
            if (e.data.event == 'spreadsheetResponseFormData') {
                let data = e.data.formData[this.objectUuid];
                data.target_users = this.flow_target_users;
                data.send_users = this.flow_send_users;
                data.select_step = this.flow_select_step;
                if (e.data.validate) {
                    this.getSelfPicker({ module: this.objectUuid }).then(res => {
                        if (res.data.code == 200) {
                            let curflowUsers = res.data.data;
                            let needTargetUseres = false;
                            if (curflowUsers.target_picker.length) {
                                needTargetUseres = true;
                            }

                            // 提交事件
                            if (this.needFlow && needTargetUseres && this.flow_picker_mum === 0 && this.flow_picker_mum !== this.flow_target_users.length) {
                                this.showFlow = true;
                                return this.$message.error('审批人未选择完整！');
                            }

                            console.log('spreadsheet submit', data);
                            this.$emit('submit', data);
                        }
                    });
                } else {
                    // 接收数据事件
                    console.log('spreadsheet receive', data);
                    this.$emit('receive', data);
                }
            }

            if (e.data.event === 'spreadsheetUploaded') {
                this.$emit('sss-uploaded');
            }
        },
        // 触发表格内表单数据提交（后续可能有验证逻辑）
        triggerSubmit() {
            this.sssIframe.contentWindow.postMessage({ event: 'spreadsheetRequestFormData', validate: true }, '*');
        },
        // 获取当前表单数据（无验证逻辑）
        getFormData() {
            this.sssIframe.contentWindow.postMessage({ event: 'spreadsheetRequestFormData', validate: false }, '*');
        },
        getSelfPicker(params) {
            return flowEngine.selfPicker(params);
        },
        // 更新宽高
        updateSize() {
            if (this.type != 'export') {
                this.sssIframe.style.width = this.parentDom.offsetWidth + 'px';
                this.sssIframe.style.height = this.parentDom.offsetHeight + 'px';
            }
        },
        showSssIframe() {
            this.sssIframe.style.top = '64px';
            this.sssIframe.style.right = '20px';
            this.sssIframe.style.zIndex = this.zIndex;
        },
        hideSssIframe() {
            this.sssIframe.style.top = '10000px';
            this.sssIframe.style.right = '10000px';
            this.sssIframe.style.zIndex = -1;
        },
        // 流程人员选择事件
        dealWithPicker(selected, total) {
            // selected 加入formdata里 target_users / send_users
            this.flow_send_users = selected.send_users;
            this.flow_target_users = selected.target_users;
            this.flow_picker_mum = total;
        },
        // 流程步骤
        getSelectStep(nodeId) {
            // nodeId 加入formdata里 ，键名 select_step
            this.flow_select_step = nodeId;
        },
        onRefresh() {
            // 刷新
            this.showFlow = false;
        },
        upload() {
            this.sssIframe.contentWindow.postMessage({
                event: 'spreadsheetTriggerUpload',
            }, '*');
        }
    },
    beforeDestroy() {
        if (this.loadType == 'preload') {
            this.sssIframe.contentWindow.postMessage({
                event: 'spreadsheetHide',
            }, '*');

            if (this.type != 'export') {
                this.observer.disconnect();
            }
        }
        
        window.removeEventListener('message', this.iframePostMessage);

        this.hideSssIframe();
    }
};
</script>

<style>
.iframe-class {
    width: 100%;
    height: 100%;
    border: none;
}
.flow-box {
    padding: 20px;
}
.create-iframe-class {
    width: 100%;
    height: 100%;
    border: none;
}
</style>