/*
 * @Author: lisushuang
 * @Date: 2021-11-18 15:12:21
 * @LastEditors: lisushuang
 * @LastEditTime: 2022-08-09 10:58:00
 * @Description: 对象操作方法mixin
 * @FilePath: /dataview-next/src/views/objects/objectOperationMixin.js
 */

import objects from "../../api/objects";
import spreadsheets from "../../api/spreadsheets";
import {  Loading, Message, MessageBox } from 'element-ui';
import { formDesign } from "../../api/home";

export default {
    methods: {

        /**
         * @description: 导出对象
         * @param {String} object_uuid 要导出的对象uuid
         * @param {String} name 要导出的对象名称
         * @param {Boolean} needData false 只需要数据，不下载
         * @return {Promise} Promise
         */
        exportObject(object_uuid, name = '', needData = false) {
            return new Promise((resolve, reject) => {
                if (needData) {
                    let loading = Loading.service({ fullscreen: true, text: "数据准备中，请稍后" })

                    objects.exportOne(object_uuid).then(res => {
                        loading.close()
                        if (res.data.code == 200) {
                            if (needData) {
                                resolve(res.data.data)
                                return
                            } else {
                                reject()
                            }
                        }
                    }).catch(() => {
                        loading.close()
                        reject()
                    })
                    return
                }
                this.$confirm('确认导出该对象的元数据吗？', '提示').then(res => {
                    if (res == 'confirm') {
                        let loading = Loading.service({ fullscreen: true, text: "数据准备中，请稍后" })

                        // 准备数据
                        objects.exportOne(object_uuid).then(res => {
                            setTimeout(() => {
                                loading.close()
                            }, 500);
                            if (res.data.code == 200) {
                                if (needData) {
                                    resolve(res.data.data)
                                    return
                                }
                                let date = new Date()
                                let content = JSON.stringify(res.data.data);
                                // 创建元素
                                var aLink = document.createElement("a");
                                // 定义文件二进制数据
                                var blob = new Blob([content], {
                                    type: "text/plain",
                                });
                                // 定义文件名称
                                aLink.download = name + '-' + object_uuid + '-' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + (date.getDate()) + ".json"
                                // 定义元素属性
                                aLink.href = URL.createObjectURL(blob);
                                // 点击a元素
                                aLink.click();
                                // 释放资源
                                URL.revokeObjectURL(blob);

                                Message.success('导出成功！')

                                resolve()
                            }
                        }).catch(() => {
                            // Message.success('导出失败！')
                            loading.close()
                            reject()
                        })
                    }
                })
            })

        },


        /**
         * @description: 导入对象
         * @param {String} target 目标服务器
         * @param {String} onlyData 直传数据，不选择文件
         * @return {*} void
         */
        importObject(target = null, onlyData = '') {
            if (onlyData) {
                onlyData = JSON.stringify(onlyData)
                let loading = Loading.service({ fullscreen: true, text: "数据处理中，请稍后" })

                objects.importOne(onlyData, target).then(res => {
                    loading.close()
                    if (res.data.code == 200) {
                        Message.success('导入成功')
                        if(this.getData !== undefined){
                          this.getData()
                        }
                        // }else{
                        // Message.error(res.data.msg)
                    }
                }).catch(res => {
                    console.error(res,'import failed !')
                    Message.error('导入失败')
                    loading.close()
                })
                return
            }
            this.$confirm("当前无版本管理，导入将进行元数据覆盖，请谨慎操作！", "提示").then(res => {
                if (res !== 'confirm') {
                    return
                }
                let loading = null
                // 获取body元素
                let body = document.getElementsByTagName("body")[0];
                // 创建input元素
                let input = document.createElement("input");
                // input元素的属性
                input.type = "file";
                input.id = "testfilesinputimport";
                // 点击input元素import { object } from '@/api/objects';

                input.click();
                // input文件变化事件
                input.onchange = () => {
                    loading = Loading.service({ fullscreen: true, text: "数据处理中，请稍后" })
                    // 获取文件
                    var file = input.files[0];
                    // let filename = file.name.split(".")[0];
                    // 读取文件
                    var reader = new FileReader();
                    // 文件读取完毕事件
                    reader.onload = () => {
                        objects.importOne(reader.result, target).then(res => {
                            loading.close()
                            if (res.data.code == 200) {
                                Message.success('导入成功')
                                if(this.getData !== undefined){
                                  this.getData()
                                }
                                // }else{
                                // Message.error(res.data.msg)
                            }
                        }).catch(() => {
                            Message.error('导入失败')
                            loading.close()
                        })

                    };
                    // 读取文件行为
                    reader.readAsText(file);
                    // 插入元素，销毁元素
                    body.appendChild(input);
                    body.removeChild(input);
                };

            }).catch(() => {

            })

        },

        /**
         * @description: 解析本地projectInfos 为选择框数据
         * @param {Boolean} exceptNow 是否排除当前所在仓库
         * @return {Array} projectDatas
         */
        _solveProjectInfo() {
            let info = localStorage.getItem('serverList', '[]')
            try {
                info = JSON.parse(info)
                let res = []
                info.forEach(item => {
                    res.push({ name: item.name, value: item })
                });
                return res
            } catch (e) {
                return []
            }
        },

        /**
         * @description: 迁移对象
         * @param {String} object_uuid 对象uuid
         * @return {*} viod
         */
        migrateObject(object_uuid) {
            // 如果是表单对象走表单对象迁移
            if (object_uuid.indexOf('formModule') > -1) {
                return this.copyFormDesign(object_uuid);
            }
            this._chooseOneServer('迁移对象').then(res => {
                this.exportObject(object_uuid, 'test', true).then(data => {
                    this.importObject(res, data)
                }).catch(() => {
                    Message.error('操作失败')
                })
            })
        },

        /**
         * @description: 同步对象数据
         * @param {String} object_uuid 对象uuid
         * @return {*} viod
         */
        syncData(object_uuid) {
            this._chooseOneServer('同步对象数据').then(res => {
                this.exportObjectData(object_uuid, 'test', true).then(data => {
                    this.importObjectData(object_uuid, res, data)
                }).catch(() => {
                    Message.error('操作失败')
                })
            })
        },

        /**
         * @description: 同步对象数据
         * @param {String} uuid 数据uuid
         * @return {*} viod
         */
        syncSheetData(uuid, options = {}) {
            let loading = Loading.service({ fullscreen: true, text: "请求原始数据..." });

            spreadsheets.detail(uuid).then(res => {
                loading.close();
                const data = res.data.data;
                this._chooseOneServer('同步表格数据').then(res => {
                    loading = Loading.service({ fullscreen: true, text: "数据迁移中..." });
                    spreadsheets.sync({ id: data.uuid, name: data.name, file: data.file }, res).then(res => {
                        loading.close();
                        if (res.data.code == 200) {
                            Message.success('操作成功');
                            if (options?.success) {
                                options.success(res.data.data);
                            }
                        }
                    }).catch(() => {
                        Message.error('同步失败');
                        loading.close();
                    });
                });
            }).catch(() => {
                loading.close();
            });
        },

        /**
         * @description: 导出对象表数据
         * @param {String} object_uuid 要导出的对象uuid
         * @param {String} name 要导出的对象名称
         * @param {Boolean} needData false 只需要数据，不下载
         * @return {Promise} Promise
         */
        exportObjectData(object_uuid, name = '', needData = false) {
            return new Promise((resolve, reject) => {
                if (needData) {
                    let loading = Loading.service({ fullscreen: true, text: "数据准备中，请稍后" })

                    objects.exportObjData(object_uuid).then(res => {
                        loading.close()
                        if (res.data.code == 200) {
                            if (needData) {
                                resolve(res.data.data)
                                return
                            } else {
                                reject()
                            }
                        }
                    }).catch(() => {
                        loading.close()
                        reject()
                    })
                    return
                }
                this.$confirm('确认导出该对象数据吗？', '提示').then(res => {
                    if (res == 'confirm') {
                        let loading = Loading.service({ fullscreen: true, text: "数据准备中，请稍后" })

                        // 准备数据
                        objects.exportObjData(object_uuid).then(res => {
                            setTimeout(() => {
                                loading.close()
                            }, 500);
                            if (res.data.code == 200) {
                                if (needData) {
                                    resolve(res.data.data)
                                    return
                                }
                                let date = new Date()
                                let content = JSON.stringify(res.data.data);
                                // 创建元素
                                var aLink = document.createElement("a");
                                // 定义文件二进制数据
                                var blob = new Blob([content], {
                                    type: "text/plain",
                                });
                                // 定义文件名称
                                aLink.download = name + '-' + object_uuid + '-' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + (date.getDate()) + ".json"
                                // 定义元素属性
                                aLink.href = URL.createObjectURL(blob);
                                // 点击a元素
                                aLink.click();
                                // 释放资源
                                URL.revokeObjectURL(blob);

                                Message.success('导出成功！')

                                resolve()
                            }
                        }).catch(() => {
                            // Message.success('导出失败！')
                            loading.close()
                            reject()
                        })
                    }
                })
            })

        },

        /**
         * @description: 导入对象表数据
         * @param {String} target 目标服务器
         * @param {String} onlyData 直传数据，不选择文件
         * @return {*} void
         */
        importObjectData(object_uuid, target = null, onlyData = '') {
            if (onlyData) {
                if (typeof (onlyData) != 'string') {
                    onlyData = JSON.stringify(onlyData)
                }

                let loading = Loading.service({ fullscreen: true, text: "数据处理中，请稍后" })

                objects.importObjData(object_uuid, onlyData, target).then(res => {
                    loading.close()
                    if (res.data.code == 200) {
                        Message.success('导入成功')
                        if(this.getData !== undefined){
                          this.getData()
                        }
                        // }else{
                        // Message.error(res.data.msg)
                    }
                }).catch(() => {
                    Message.error('导入失败')
                    loading.close()
                })
                return
            }
            this.$confirm("当前无版本管理，导入将进行元数据覆盖，请谨慎操作！", "提示").then(res => {
                if (res !== 'confirm') {
                    return
                }
                let loading = null
                // 获取body元素
                let body = document.getElementsByTagName("body")[0];
                // 创建input元素
                let input = document.createElement("input");
                // input元素的属性
                input.type = "file";
                input.id = "testfilesinputimport";
                // 点击input元素import { object } from '@/api/objects';

                input.click();
                // input文件变化事件
                input.onchange = () => {
                    loading = Loading.service({ fullscreen: true, text: "数据处理中，请稍后" })
                    // 获取文件
                    var file = input.files[0];
                    // let filename = file.name.split(".")[0];
                    // 读取文件
                    var reader = new FileReader();
                    // 文件读取完毕事件
                    reader.onload = () => {
                        objects.importObjData(object_uuid, reader.result, target).then(res => {
                            loading.close()
                            if (res.data.code == 200) {
                                Message.success('导入成功')
                                if(this.getData !== undefined){
                                  this.getData()
                                }
                                // }else{
                                // Message.error(res.data.msg)
                            }
                        }).catch(() => {
                            Message.error('导入失败')
                            loading.close()
                        })

                    };
                    // 读取文件行为
                    reader.readAsText(file);
                    // 插入元素，销毁元素
                    body.appendChild(input);
                    body.removeChild(input);
                };

            }).catch(() => {

            })

        },


        /**
         * @description: 弹出服务器选择框
         * @param {String} doText 展示的数据
         * @return {Promise} resolve(serverInfo)
         */
        _chooseOneServer(doText = "迁移数据") {
            return new Promise((resolve) => {
                const h = this.$createElement
                const infos = this._solveProjectInfo()
                let value = ''
                let options = []
                infos.forEach((item) => {
                    options.push(h('el-option', {
                        props: {
                            label: item.name,
                            value: item
                        }
                    }))
                })
                // 渲染的select选择服务器
                let ok = h('el-select', {
                    props: {
                        value: value,
                        name: 'test'
                    },
                    on: {
                        input: (v) => {
                            ok.componentInstance.selected.currentLabel = v.name
                            value = v
                        }
                    },
                    style: {
                        width: '100%'
                    }
                }, [options])
                MessageBox({
                    title: "请选择服务器" + doText,
                    message: ok,
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            // instance.confirmButtonLoading = true;
                            // instance.confirmButtonText = '执行中...';
                            if (!value) {
                                Message.info('请选择一条数据')
                                return
                            }
                            instance = null
                            resolve(value)
                            done()
                        } else {
                            instance.confirmButtonLoading = false;
                            done();
                        }
                    }
                })
            })
        },


        /**
         * @description: 页面迁移
         * @param {String} page_uuid
         * @return {*} void
         */
        migratePage(page_uuid) {
            this._chooseOneServer('迁移页面数据').then(res => {
                let loading = Loading.service({ fullscreen: true, text: "数据迁移中..." })

                // 先查找对方服务器数据
                objects.mapi.common({
                    __method_name__: 'dataList',
                    transcode: 0,
                    object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
                    view_uuid: '175c6fe7-c8a2-4530-99be-80a8f1113f3c',
                    search: [
                        { code: 'uuid', ruleType: 'eq', value: page_uuid }
                    ]
                }, res).then(otherSearch => {
                    if (otherSearch.data.code == 200) {
                        let data = otherSearch.data.data.data
                        objects.mapi.common({
                            __method_name__: 'dataList',
                            transcode: 0,
                            object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
                            view_uuid: '175c6fe7-c8a2-4530-99be-80a8f1113f3c',
                            search: [
                                { code: 'uuid', ruleType: 'eq', value: page_uuid }
                            ]
                        }).then(myData => {
                            if (myData.data.code == 200) {
                                myData = myData.data.data.data
                                if (myData && myData.length && myData[0]) {
                                    let upsert = {
                                        transcode: 0,
                                        object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
                                        view_uuid: '175c6fe7-c8a2-4530-99be-80a8f1113f3c',
                                        ...myData[0],
                                    }
                                    delete upsert.updated_at
                                    delete upsert.created_at
                                    if (data && data.length && data[0]) {//修改
                                        upsert.data_id = data[0].id
                                        upsert.__method_name__ = 'updateData'
                                    } else {//新增
                                        upsert.__method_name__ = 'createData'
                                    }
                                    objects.mapi.common(upsert, res).then(res => {
                                        loading.close()
                                        if (res.data.code == 200) {
                                            Message.success('操作成功')
                                        }
                                    }).catch(() => {
                                        loading.close()
                                    })
                                } else {
                                    Message.error('查找本服务器中页面数据失败')
                                    loading.close()
                                }
                            }
                        }).catch(() => {
                            loading.close()
                        })

                    }

                }).catch(() => {
                    loading.close()
                    Message.error('请求页面数据错误')
                })
            })
        },

        copyPage() {
            objects.mapi.common({})
        },

        /**
         * @description: 全局字典迁移，覆盖式迁移
         * @param {*} dict_uuid
         * @return {*}
         */
        migrateGlobalDict(dict_uuid) {
            if (!dict_uuid) {
                Message.error('错误的字典uuid')
                return
            }
            this._chooseOneServer('迁移字典').then(target => {
                let loading = Loading.service({ fullscreen: true, text: "数据迁移中..." })
                objects.getDictDetail(dict_uuid).then(res => {
                    if (res.data.code == 200) {
                        objects.metaDictMigrate(target, { data: JSON.stringify(res.data.data) }).then(res => {
                            loading.close()

                            if (res.data.code == 200) {
                                Message.success('操作成功')
                            }
                        }).catch(() => loading.close())
                    }
                }).catch(() => loading.close())
            })
        },

        copyGlobalDict() {

        },

        copyFormDesign(module_uuid) {
            if (!module_uuid) {
                return Message.error('错误的表单uuid');
            }
            this._chooseOneServer('迁移表单').then(target => {
                let loading = Loading.service({ fullscreen: true, text: "数据迁移中..." });
                formDesign.formShow(module_uuid).then(res => {
                    if (res.data.code == 200) {
                        let temp = res.data.data;
                        delete temp.archi_id;
                        delete temp.archi_type;
                        delete temp.archi_table;
                        objects.formDesginMigrate(target, module_uuid, temp).then(res => {
                            loading.close()

                            if (res.data.code == 200) {
                                Message.success('操作成功')
                            }
                        }).catch(() => loading.close())
                    }
                }).catch(() => loading.close())
            });
        },

    }
}
